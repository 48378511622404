import React,{useState,useEffect} from 'react'
import imagen from "../assets/LOGO COMPLETO CCG NEGRO.png";
import "../css/menu.css"
const fecha = new Date();



function FacturaM({...props}) {
  document.oncontextmenu = function(){return false}
    const[correlativo,setCorrelativo]=useState("")
    const [ocultar, setOcultar] = useState("none")
    const [mostrarContenedorP, setMostrarContenedorP] = useState("none");
    const [limite1, setlimite1] = useState("20/05/2023");
    const [limite2, setlimite2] = useState("29/06/2023");
    const [limite3, setlimite3] = useState("16/08/2023");
    const [limiteA, setlimiteA] = useState("");
    const [texto, settexto] = useState("Transferencia N°");
  
    const [rango1, setrango1] = useState(
      "000-003-01-00000001-000-003-01-00050000"
    );
    const [rango2, setrango2] = useState(
      "000-003-01-00050001-000-003-01-00100000"
    );
  
    const [rango3, setrango3] = useState(
      "000-003-01-00100001-000-003-01-00150000"
    );
    const [rangoA, setrangoA] = useState("");
  
    const [cai1, setcai1] = useState("9E41AF-EE62C8-E34D9D-2F6D59-5CF268-E8");
    const [cai2, setcai2] = useState("11F6C7-8E7304-5A4CB3-9482AB-77651B-A5");
    const [cai3, setcai3] = useState("4C5864-0B43DC-5D48A2-9E6858-1D494E-69");
    const [caiA, setcaiA] = useState("");


    const recargar=()=>{
      props.setDisabledEnviar("")
      props.setMostrarContenedorF("")  
      props.setMostrarContenedorF2("none")
      props.setMostrarLista("")
      props.setColor("white")
      props.setMostrarDias("none")
      const color1=props.colores[0]
      color1("")
      const color2=props.colores[1]
      color2("")
      const color3=props.colores[2]
      color3("")
      const color4=props.colores[3]
      color4("")

      const opcion1=props.opciones[0]
      opcion1("")
      const opcion2=props.opciones[1]
      opcion2("")
      const opcion3=props.opciones[2]
      opcion3("")
      const opcion4=props.opciones[3]
      opcion4("")
      const opcion5=props.opciones[4]
      opcion5("")
      const opcion6=props.opciones[5]
      opcion6("")
      const opcion7=props.opciones[6]
      opcion7("")
      const opcion8=props.opciones[7]
      opcion8("")

      const value1=props.values2[0]
      value1("")
      const value2=props.values2[1]
      value2("")
      const value3=props.values2[2]
      value3("")
      const value4=props.values2[3]
      value4("")
      const value5=props.values2[4]
      value5("")
      const value6=props.values2[5]
      value6("")
      const value7=props.values2[6]
      value7("")
      const value8=props.values2[7]
      value8("")
      const value9=props.values2[8]
      value9("")
      const value10=props.values2[9]
      value10("")
      const value11=props.values2[10]
      value11("")
      const value12=props.values2[11]
      value12(0)
      const value13=props.values2[12]
      value13("")
      const value14=props.values2[13]
      value14("")
      const value15=props.values2[14]
      value15(0)
      const value16=props.values2[15]
      value16(0)
      const value17=props.values2[16]
      value17("")
      props.setpago("Efectivo")
      props.setbanco("")
      props.settransferencia("")
      props.setmTransferencia("none")
      props.setcheck1(true)
      props.setcheck2(false)
      props.setcheck3(false)
    }

    useEffect(() => {
      if (props.pago === "Efectivo" || props.pago === "Transferencia" ) {
        settexto("Transferencia N°");
      } else if (props.pago === "Tarjeta") {
        settexto("Comprobante Pago N°");
      }
    }, [props.pago]);


    useEffect(() => {

      if(props.values[3]==="Otro"){
        setOcultar("")
      }else if(props.values[3]==="Consumidor Final")
      {
        setOcultar("none")
      }
    
    }, [props.values[3]])
  

    useEffect(() => {

      if(props.values[2]==="Completo"){
        setMostrarContenedorP("none")
      }else if(props.values[2]==="Parcial")
      {
        setMostrarContenedorP("")
      }
    
    }, [props.values[2]])

    useEffect(() => {
      if (props.tipoC === "Otro") {
        setOcultar("");
      } else if (props.tipoC === "Consumidor Final") {
        setOcultar("none");
      }
    }, [props.tipoC]);
  
    useEffect(() => {
    if(parseInt(props.values[15]) <= parseInt(910000)){
        setlimiteA("11/03/2025");
      setrangoA("000-003-01-00710001-000-003-01-00810000");
      setcaiA("8B6B74-3FC25C-234789-D114B6-852CEB-5A");
      setCorrelativo("000-003-01-00");  
      }else{
        setlimiteA("11/06/2025");
        setrangoA("000-003-01-00910001 / 000-003-01-01010000");
        setcaiA("21E258-281E55-9F41E0-63BE03-0909AB-8C");
        setCorrelativo("000-003-01-00"); 
      }
    
    
    }, [props.values[15]]);
  
  


  return (
  <React.Fragment>
     <div className='ContenedorMostrarF'>
        <section>
          <h1>Centro Civíco</h1>
          <h1>Gubernamental</h1>
          <img src={imagen} className='imagen' alt='Logo' onClick={recargar}/>
        </section>

        <section className='contenedorDatosFactura'>
          
          <h2 style={{marginTop:"1.5rem"}}>
              <b>{fecha.getDate() +
                "/" +
                (fecha.getMonth() + parseInt(1)) +
                "/" +
                fecha.getFullYear()}
                </b>
            </h2>
  
            <label className='labelDatosFactura'>
              <b>Concepto: Pago de Estacionamiento</b>
            </label>
            <label className='labelDatosFactura'>Auto Impresión</label>
            <label className='labelDatosFactura'>CAI:</label>
            <label className='labelDatosFacturac'>{caiA}</label>
            <label className='labelDatosFactura'>DOIH, SA DE CV</label>
            <label className='labelDatosFactura'>RTN: 08019016827575 </label>
            <label className='labelDatosFactura'>Rango de Impresión:</label>
            <label className='labelDatosFacturac'>
            {rangoA}
            </label>
            <label className='labelDatosFactura'>
            Límite de Emisión: {limiteA}
            </label>
            <label className='labelDatosFacturac'>
              Factura Original N°:{correlativo+props.values[15]}
            </label>
          </section>
  
          <section className='contenedorInfoFactura'>
            <label style={{ fontSize: "2rem" }}>Cliente: {props.values[3]}</label>
            <label style={{ fontSize: "1.8rem",display:`${ocultar}` }}>
              RTN: <span style={{ fontSize: "1.6rem" }}>{props.values[12]}</span>
            </label>
            <label style={{ fontSize: "1.8rem", width: "auto" ,display:`${ocultar}` }}>
              Nombre: {props.values[13]}
            </label>
  
            <label style={{ fontSize: "2rem", marginTop: "1rem", width:"auto" }}>
              Servicio: Estacionamiento Mensual
              <span style={{ fontSize: "2rem" }}></span>
            </label>
            <label style={{ fontSize: "2rem", width:"auto" }}>
              Tipo Estacionamiento: {props.values[1]}
              <span style={{ fontSize: "2rem" }}></span>
            </label>
            <label style={{ fontSize: "2rem", width:"auto" }}>
              Tipo Pago: {props.values[2]}
              <span style={{ fontSize: "2rem" }}></span>
            </label>

            <label style={{ fontSize: "2rem", width:"auto", display:`${mostrarContenedorP}` }}>
              Cantidad de Días: {props.values[14]}
              <span style={{ fontSize: "2rem" }}></span>
            </label>

            <label style={{ fontSize: "2rem",  width:"auto" }}>
              Tipo Vehículo: {props.values[5]}
              <span style={{ fontSize: "2rem" }}></span>
            </label>
            <label style={{ fontSize: "2rem", width:"auto" }}>
              Nombre: {props.values[4]}
              <span style={{ fontSize: "2rem" }}></span>
            </label>
            <label style={{ fontSize: "2rem", width:"auto" }}>
              Institución: {props.values[16]}
              <span style={{ fontSize: "2rem" }}></span>
            </label>
            <label style={{ fontSize: "2rem", width:"auto" }}>
              Marca: {props.values[6]}
              <span style={{ fontSize: "2rem" }}></span>
            </label>
            <label style={{ fontSize: "2rem", width:"auto" }}>
              Modelo: {props.values[7]}
              <span style={{ fontSize: "2rem" }}></span>
            </label>
            <label style={{ fontSize: "2rem", width:"auto" }}>
              Placa: {props.values[8]}
              <span style={{ fontSize: "2rem" }}></span>
            </label>
            <label style={{ fontSize: "2rem", width:"auto" }}>
              Teléfono: {props.values[9]}
              <span style={{ fontSize: "2rem" }}></span>
            </label>
            <label style={{ fontSize: "2rem", width:"auto" }}>
              Subtotal: {`LPS.${parseFloat(props.values[11]/1.15).toFixed(2)}`}
              <span style={{ fontSize: "2rem" }}></span>
            </label>
            <label style={{ fontSize: "2rem", width:"auto" }}>
              ISV: {`LPS.${(parseFloat(props.values[11]).toFixed(2)-parseFloat(props.values[11]/1.15).toFixed(2)).toFixed(2)}`}
              <span style={{ fontSize: "2rem" }}></span>
            </label>
            <label style={{ fontSize: "2rem", width:"auto" }}>
              Total: {`LPS.${parseFloat(props.values[11]).toFixed(2)}`}
              <span style={{ fontSize: "2rem" }}></span>
            </label>

            <label style={{ fontSize: "2rem" }}>
            Forma de Pago: {props.pago}
          </label>
          <label style={{ fontSize: "2rem",display:`${props.mTransferencia}`}}>
            Banco: {props.banco}
          </label>
          <label style={{ fontSize: "2rem",display:`${props.mTransferencia}` }}>
            {texto}: {props.transferencia}
          </label>
          
            <label style={{ fontSize: "2rem", width:"auto" }}>
              Recibido: {`LPS.${parseFloat(props.values[10]).toFixed(2)}`}
              <span style={{ fontSize: "2rem" }}></span>
            </label>
            <label style={{ fontSize: "2rem", width:"auto" }}>
              Cambio: {`LPS.${(parseFloat(props.values[10])-parseFloat(props.values[11])).toFixed(2)}`}
              <span style={{ fontSize: "2rem" }}></span>
            </label>
          </section>
          <b>
          <p style={{ width: "35rem", fontSize: "1.2rem", textAlign: "start",fontFamily: "PT Serif, serif" }}>
            Dirección: Blvd. Juan Pablo Segundo esquina con República de Corea
            Tegucigalpa, M.D.C. Honduras C.A. Teléfono: +5042243-7241. Correo
            electrónico: Ives.jimenez@doih.hn
          </p>{" "}
        </b>

    </div>

  </React.Fragment>
  )
}

export default FacturaM